import React, { Fragment, useState, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import './../styles/custom.css'

import axios from 'axios'
import config from './../config'
import { toast } from 'react-toastify'

import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Button from '@material-ui/core/Button'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import MaskedInput from 'react-text-mask'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

// import Contrato from './../components/ContratoV2'
import ContratoV3 from './../components/ContratoV3'
import Logo from './../img/gestaumdigital.png'

const useStyles = makeStyles((theme) => ({
    title: {
      textAlign: 'center',
      fontSize: '5em',
      marginTop: '.5em',
      marginBottom: '.5em'
    },
    root: {
        width: '100%',
        maxWidth: '900px',
        marginRight: 'auto',
        marginLeft: 'auto',
        marginBottom: '4em'
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    contentContainer: {
        padding: theme.spacing(4),
    },
    buttonContainer: {
        marginTop: theme.spacing(2),
        textAlign: 'center'
    },
    termsAndConditions: {
        fontSize: '1.2em'
    },
    termsContainer: {
        maxHeight: '500px',
        overflowY: 'scroll'
    },
    textCenter: {
        textAlign: 'center'
    },
    hr: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    logo: {
        maxWidth: '250px'
    },
    addContainer: {
        textAlign: 'right'
    },
    planoContainer: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4)
    }
}))

function TelefoneMask(props) {
    const { inputRef, ...other } = props
  
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
            inputRef(ref ? ref.inputElement : null);
            }}
            mask={['(', /[0-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            // showMask
        />
    )
}

function CelularMask(props) {
    const { inputRef, ...other } = props
  
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
            inputRef(ref ? ref.inputElement : null);
            }}
            mask={['(', /[0-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            // showMask
        />
    )
}

function CpfMask(props) {
    const { inputRef, ...other } = props
  
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
            inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/[0-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
            placeholderChar={'\u2000'}
            // showMask
        />
    )
}

function CnpjMask(props) {
    const { inputRef, ...other } = props
  
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
            inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/[0-9]/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
            placeholderChar={'\u2000'}
            // showMask
        />
    )
}

function getSteps() {
    return [
        'Dados da Empresa',
        'Dados do Representante(s) Legal(is)',
        'Termos e condições', 
        'Aceitar e finalizar',
        'Conclusão'
    ]
}

function Index(props) {
    const classes = useStyles()

    const [activeStep, setActiveStep] = useState(0)
    const [skipped, setSkipped] = useState(new Set())
    const steps = getSteps()
    const [termsRead, setTermsRead] = useState(false)
    const [socios, setSocios] = useState([1])
    const [enviando, setEnviando] = useState(0)

    const [showIndicacaoAmigo, setShowIndicacaoAmigo] = useState(false)
    const [showOutros, setShowOutros] = useState(false)

    const [accept1, setAccept1] = useState(false)
    const [accept2, setAccept2] = useState(false)

    let atividade = props.match.params.atividade
    let plano = props.match.params.plano

    const initialData = {
        como_conheceu: '',
        atividade: '',
        plano: '',
        cep: '',
        bairro: '',
        cidade: '',
        endereco: '',
        estado: '',
        nome_do_amigo: '',
        telefone_do_amigo: '',
        email_do_amigo: '',
        onde_conheceu: '',
        nome_da_empresa: '',
        cnpj: '',
        celular_empresa: '',
        telefone_empresa: '',
        email: '',
        site: '',
        complemento: '',
        numero: ''
    }

    switch (atividade) {
        case 'mei':
            initialData.atividade = 'MEI'
            break;
        case 'simples_servicos':
            initialData.atividade = 'Simples Serviços'
            break;
        case 'simples_comercio':
            initialData.atividade = 'Simples Comércio'
            break;
        case 'simples_servicos_comercio':
            initialData.atividade = 'Simples Serviços e Comércio'
            break;
        default:
            initialData.atividade = 'MEI'
            break;
    }
    switch(plano) {
        case 'classico':
            initialData.plano = 'Clássico'
            break;
        case 'prime':
            initialData.plano = 'Prime'
            break;
        case 'diamante':
            initialData.plano = 'Diamante'
            break;
        default:
            initialData.plano = 'Clássico'
            break;
    }

    const [data, setData] = useState(initialData)

    const isStepSkipped = useCallback((step) => {
        return skipped.has(step)
    }, [skipped])

    const handleNext = useCallback(() => {
        let newSkipped = skipped
        if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep)
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1)
        setSkipped(newSkipped)
    }, [activeStep, isStepSkipped, skipped])

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    const handleScroll = useCallback((e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        // const bottom = e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight
        if (bottom) setTermsRead(true)
    }, [setTermsRead])

    const submit = useCallback(() => {
        const url = `${config.api}/registrar`
        axios.post(url, data)
            .then((response) => {
                console.log(response)
                setEnviando(1)
            })
            .catch((error) => {
                console.log(error)
            })
        handleNext()
    }, [data, handleNext, setEnviando])

    const buscarCep = useCallback((value) => {
        const url = `https://viacep.com.br/ws/${value}/json/`
        axios.get(url)
            .then((response) => {
                const cep = response.data
                if (!cep.erro) {
                    // console.log(cep)
                    setData({
                        ...data,
                        bairro: cep.bairro,
                        cidade: cep.localidade,
                        endereco: cep.logradouro,
                        estado: cep.uf,
                        cep: value
                    })
                }
            })
    }, [data, setData])

    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(String(email).toLowerCase())
    }

    function validatePhone(number) {
        number = number.replace(/\D+/g, '')
        if (number.length < 8) return false
        else return true
    }

    function validarCNPJ(value) {
        if (!value) return false

        const validTypes =
          typeof value === 'string' || Number.isInteger(value) || Array.isArray(value)
      
        if (!validTypes) return false
      
        // Guarda um array com todos os dígitos do valor
        const match = value.toString().match(/\d/g)
        const numbers = Array.isArray(match) ? match.map(Number) : []
      
        // Valida a quantidade de dígitos
        if (numbers.length !== 14) return false
        
        // Elimina inválidos com todos os dígitos iguais
        const items = [...new Set(numbers)]
        if (items.length === 1) return false
      
        // Cálculo validador
        const calc = (x) => {
          const slice = numbers.slice(0, x)
          let factor = x - 7
          let sum = 0
      
          for (let i = x; i >= 1; i--) {
            const n = slice[x - i]
            sum += n * factor--
            if (factor < 2) factor = 9
          }
      
          const result = 11 - (sum % 11)
      
          return result > 9 ? 0 : result
        }
      
        // Separa os 2 últimos dígitos de verificadores
        const digits = numbers.slice(12)
        
        // Valida 1o. dígito verificador
        const digit0 = calc(12)
        if (digit0 !== digits[0]) return false
      
        // Valida 2o. dígito verificador
        const digit1 = calc(13)
        return digit1 === digits[1]
    }

    function isValidCPF(cpf) {
        if (typeof cpf !== "string") return false
        cpf = cpf.replace(/[\s.-]*/igm, '')
        if (
            !cpf ||
            cpf.length !== 11 ||
            cpf === "00000000000" ||
            cpf === "11111111111" ||
            cpf === "22222222222" ||
            cpf === "33333333333" ||
            cpf === "44444444444" ||
            cpf === "55555555555" ||
            cpf === "66666666666" ||
            cpf === "77777777777" ||
            cpf === "88888888888" ||
            cpf === "99999999999" 
        ) {
            return false
        }
        var soma = 0
        var resto
        for (var i = 1; i <= 9; i++) 
            soma = soma + parseInt(cpf.substring(i-1, i)) * (11 - i)
        resto = (soma * 10) % 11
        if ((resto === 10) || (resto === 11))  resto = 0
        if (resto !== parseInt(cpf.substring(9, 10)) ) return false
        soma = 0
        for (i = 1; i <= 10; i++) 
            soma = soma + parseInt(cpf.substring(i-1, i)) * (12 - i)
        resto = (soma * 10) % 11
        if ((resto === 10) || (resto === 11))  resto = 0
        if (resto !== parseInt(cpf.substring(10, 11) ) ) return false
        return true
    }

    const preEnvio = useCallback(() => {
        const url = `${config.api}/ante_registro1`
        axios.post(url, data)
            .then((response) => {
                console.log(response)
                // setEnviando(1)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [data])

    const validarEmpresa = useCallback(() => {
        let valid = true
        if (data.nome_da_empresa === undefined || data.nome_da_empresa === '' || data.nome_da_empresa === ' ') {
            valid = false
            toast.error(`O campo 'Nome da Empresa' é obrigatório`)
        }
        if (data.cnpj === undefined || data.cnpj === '' || data.cnpj === ' ') {
            valid = false
            toast.error(`O campo 'CNPJ' é obrigatório`)
        } else if (!validarCNPJ(data.cnpj)) {
            valid = false
            toast.error(`Insira um CNPJ válido`)
        }
        if (data.cep === undefined || data.cep === '' || data.cep === ' ') {
            valid = false
            toast.error(`O campo 'CEP' é obrigatório`)
        }
        if (data.endereco === undefined || data.endereco === '' || data.endereco === ' ') {
            valid = false
            toast.error(`O campo 'Endereço' é obrigatório`)
        }
        if (data.bairro === undefined || data.bairro === '' || data.bairro === ' ') {
            valid = false
            toast.error(`O campo 'Bairro' é obrigatório`)
        }
        if (data.numero === undefined || data.numero === '' || data.numero === ' ') {
            valid = false
            toast.error(`O campo 'Número' é obrigatório`)
        }
        if (data.cidade === undefined || data.cidade === '' || data.cidade === ' ') {
            valid = false
            toast.error(`O campo 'Cidade' é obrigatório`)
        }
        if (data.estado === undefined || data.estado === '' || data.estado === ' ') {
            valid = false
            toast.error(`O campo 'Estado' é obrigatório`)
        }
        if (data.email === undefined || data.email === '' || data.email === ' ') {
            valid = false
            toast.error(`O campo 'Email' é obrigatório`)
        } else if (!validateEmail(data.email)) {
            valid = false
            toast.error(`Insira um email válido`)
        }
        if (data.telefone_empresa === undefined || data.telefone_empresa === '' || data.telefone_empresa === ' ') {
            valid = false
            toast.error(`O campo 'Telefone da Empresa' é obrigatório`)
        }else if (!validatePhone(data.telefone_empresa)) {
            valid = false
            toast.error(`Insira um telefone válido`)
        }
        if (data.celular_empresa === undefined || data.celular_empresa === '' || data.celular_empresa === ' ') {
            valid = false
            toast.error(`O campo 'Celular da Empresa' é obrigatório`)
        } else if (!validatePhone(data.celular_empresa)) {
            valid = false
            toast.error(`Insira um celular válido`)
        }

        if (data.como_conheceu === undefined || data.como_conheceu === '' || data.como_conheceu === ' ') {
            valid = false
            toast.error(`Por favor preencha como conheceu a nossa empresa`)
        }

        if (valid) {
            preEnvio()
            handleNext()
        }
    }, [data, handleNext, preEnvio])

    const validarSocios = useCallback(() => {
        let valid = true

        socios.map((v) => {
            if (data[`s${v}_nome`] === undefined || data[`s${v}_nome`] === '' || data[`s${v}_nome`] === ' ') {
                valid = false
                toast.error(`Insira um nome para o representante legal ${v}`)
            }
            if (data[`s${v}_email`] === undefined || data[`s${v}_email`] === '' || data[`s${v}_email`] === ' ') {
                valid = false
                toast.error(`Insira um email para o representante legal ${v}`)
            } else if (!validateEmail(data[`s${v}_email`])) {
                valid = false
                toast.error(`Insira um email válido para o representante legal ${v}`)
            }
            if (data[`s${v}_cpf`] === undefined || data[`s${v}_cpf`] === '' || data[`s${v}_cpf`] === ' ') {
                valid = false
                toast.error(`Insira um CPF para o representante legal ${v}`)
            } else if (!isValidCPF(data[`s${v}_cpf`])) {
                valid = false
                toast.error(`Insira um CPF válido para o representante legal ${v}`)
            }
            if (data[`s${v}_telefone`] === undefined || data[`s${v}_telefone`] === '' || data[`s${v}_telefone`] === ' ') {
                valid = false
                toast.error(`Insira um telefone para o representante legal ${v}`)
            }else if (!validatePhone(data[`s${v}_telefone`])) {
                valid = false
                toast.error(`Insira um telefone válido para o representante legal ${v}`)
            }
            if (data[`s${v}_celular`] === undefined || data[`s${v}_celular`] === '' || data[`s${v}_celular`] === ' ') {
                valid = false
                toast.error(`Insira um celular para o representante legal ${v}`)
            }else if (!validatePhone(data[`s${v}_celular`])) {
                valid = false
                toast.error(`Insira um celular válido para o representante legal ${v}`)
            }
            return true
        })
        
        if (valid) {
            preEnvio()
            handleNext()
        }
    }, [data, handleNext, socios, preEnvio])

    return (
        <Fragment>
            <Container>
                <Typography variant='h1' className={classes.title}>
                    <img src={Logo} className={classes.logo} alt="Gestaum Contabilidade" />
                </Typography>

                <Paper className={classes.root}>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                            const stepProps = {}
                            const labelProps = {}
                            
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            )
                        })}
                    </Stepper>
                    <div className={classes.contentContainer}>
                        {/* STEP 1 */}
                        {activeStep === 0 && (
                            <Fragment>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <p className={classes.textCenter}>Obrigado por se juntar a Gestaum e antes de iniciar poderia dizer como conheceu a Gestaum?</p>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel id="como-conheceu-label">Como conheceu a Gestaum Digital</InputLabel>
                                            <Select
                                            labelId="como-conheceu-label"
                                            id="como-conheceu"
                                            value={data.como_conheceu}
                                            onChange={(e) => {
                                                setData({...data, como_conheceu: e.target.value})
                                                if (e.target.value === 'Indicação de um amigo') {
                                                    setShowIndicacaoAmigo(true)
                                                    setShowOutros(false)
                                                } else if (e.target.value === 'Outros') {
                                                    setShowIndicacaoAmigo(false)
                                                    setShowOutros(true)
                                                } else {
                                                    setShowIndicacaoAmigo(false)
                                                    setShowOutros(false)
                                                }
                                            }}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
            
                                                <MenuItem value={'Pesquisa no Google'}>Pesquisa no Google</MenuItem>
                                                <MenuItem value={'Facebook'}>Facebook</MenuItem>
                                                <MenuItem value={'Instagram'}>Instagram</MenuItem>
                                                <MenuItem value={'LinkedIn'}>LinkedIn</MenuItem>
                                                <MenuItem value={'Twitter'}>Twitter</MenuItem>
                                                <MenuItem value={'YouTube'}>YouTube</MenuItem>
                                                <MenuItem value={'Indicação de um amigo'}>Indicação de um amigo</MenuItem>
                                                <MenuItem value={'Outros'}>Outros</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    {showIndicacaoAmigo && (
                                        <Fragment>
                                            <Grid item xs={4}>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor='nome_do_amigo'>Nome do amigo</InputLabel>
                                                    <Input 
                                                    id='nome_do_amigo'
                                                    value={data.nome_do_amigo}
                                                    onChange={(e) => setData({...data, nome_do_amigo: e.target.value})} />                                            
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor='telefone_do_amigo'>Telefone do amigo</InputLabel>
                                                    <Input 
                                                    id='telefone_do_amigo'
                                                    value={data.telefone_do_amigo}
                                                    onChange={(e) => setData({...data, telefone_do_amigo: e.target.value})} />                                            
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor='email_do_amigo'>Email do amigo</InputLabel>
                                                    <Input 
                                                    id='email_do_amigo'
                                                    value={data.email_do_amigo}
                                                    onChange={(e) => setData({...data, email_do_amigo: e.target.value})} />                                            
                                                </FormControl>
                                            </Grid>
                                        </Fragment>
                                    )}
                                    {showOutros && (
                                        <Fragment>
                                            <Grid item xs={12}>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor='onde_conheceu'>Outros? Qual?</InputLabel>
                                                    <Input 
                                                    id='onde_conheceu'
                                                    value={data.onde_conheceu}
                                                    onChange={(e) => setData({...data, onde_conheceu: e.target.value})} />                                            
                                                </FormControl>
                                            </Grid>
                                        </Fragment>
                                    )}
                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <InputLabel id="atividade-label">Atividade</InputLabel>
                                            <Select
                                            labelId="atividade-label"
                                            id="atividade"
                                            value={data.atividade}
                                            onChange={(e) => setData({...data, atividade: e.target.value})}
                                            >
                                                <MenuItem value={'MEI'}>MEI</MenuItem>
                                                <MenuItem value={'Simples Serviços'}>Simples Serviços</MenuItem>
                                                <MenuItem value={'Simples Comércio'}>Simples Comércio</MenuItem>
                                                <MenuItem value={'Simples Serviços e Comércio'}>Simples Serviços e Comércio</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <InputLabel id="plano-label">Plano</InputLabel>
                                            <Select
                                            labelId="plano-label"
                                            id="plano"
                                            value={data.plano}
                                            onChange={(e) => setData({...data, plano: e.target.value})}
                                            >
                                                <MenuItem value={'Clássico'}>Clássico</MenuItem>
                                                <MenuItem value={'Prime'}>Prime</MenuItem>
                                                <MenuItem value={'Diamante'}>Diamante</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor='nome_da_empresa'>Nome Completo da Empresa *</InputLabel>
                                            <Input 
                                            id='nome_da_empresa'
                                            value={data.nome_da_empresa}
                                            onChange={(e) => setData({...data, nome_da_empresa: e.target.value})} />                                            
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor='cnpj'>CNPJ *</InputLabel>
                                            <Input 
                                            id='cnpj'
                                            value={data.cnpj}
                                            onChange={(e) => setData({...data, cnpj: e.target.value})}
                                            inputComponent={CnpjMask} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor='cep'>CEP *</InputLabel>
                                            <Input 
                                            id='cep'
                                            value={data.cep}
                                            onChange={(e) => {
                                                setData({...data, cep: e.target.value})
                                                if (e.target.value.length === 8) buscarCep(e.target.value)
                                            }} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor='endereco'>Endereço *</InputLabel>
                                            <Input 
                                            id='endereco'
                                            value={data.endereco}
                                            onChange={(e) => setData({...data, endereco: e.target.value})} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor='bairro'>Bairro *</InputLabel>
                                            <Input 
                                            id='bairro'
                                            value={data.bairro}
                                            onChange={(e) => setData({...data, bairro: e.target.value})} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor='numero'>Número *</InputLabel>
                                            <Input 
                                            id='numero'
                                            value={data.numero}
                                            onChange={(e) => setData({...data, numero: e.target.value})} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor='complemento'>Complemento</InputLabel>
                                            <Input 
                                            id='complemento'
                                            value={data.complemento}
                                            onChange={(e) => setData({...data, complemento: e.target.value})} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor='cidade'>Cidade *</InputLabel>
                                            <Input 
                                            id='cidade'
                                            value={data.cidade}
                                            onChange={(e) => setData({...data, cidade: e.target.value})} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor='estado'>Estado *</InputLabel>
                                            <Input 
                                            id='estado'
                                            value={data.estado}
                                            onChange={(e) => setData({...data, estado: e.target.value})} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor='site'>Site</InputLabel>
                                            <Input 
                                            id='site'
                                            value={data.site}
                                            onChange={(e) => setData({...data, site: e.target.value})} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor='email'>Email *</InputLabel>
                                            <Input 
                                            id='email'
                                            value={data.email}
                                            onChange={(e) => setData({...data, email: e.target.value})} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor='telefone_empresa'>Telefone empresa *</InputLabel>
                                            <Input 
                                            id='telefone_empresa'
                                            value={data.telefone_empresa}
                                            onChange={(e) => setData({...data, telefone_empresa: e.target.value})}
                                            inputComponent={TelefoneMask} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor='celular_empresa'>Celular empresa *</InputLabel>
                                            <Input 
                                            id='celular_empresa'
                                            value={data.celular_empresa}
                                            onChange={(e) => setData({...data, celular_empresa: e.target.value})}
                                            inputComponent={CelularMask} />
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <div className={classes.buttonContainer}>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        onClick={validarEmpresa}
                                        className={classes.button}
                                    >
                                        Próximo
                                    </Button>
                                </div>
                            </Fragment>
                        )}

                        {/* STEP 2 */}
                        {activeStep === 1 && (
                            <Fragment>
                                <p className={classes.addContainer}>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        onClick={() => {
                                            if (socios.length < 10) {
                                                let last = socios[socios.length - 1]
                                                setSocios([...socios, last+1])
                                            }
                                        }}
                                        className={classes.button}
                                    >
                                        + Representante legal
                                    </Button>
                                </p>
                                {socios.map((v) => {
                                    const label = (v === 1) ? `Representante legal` : `Representante legal ${v}`
                                    return (
                                        <Fragment key={v}>
                                            {v > 1 && (
                                                <hr className={classes.hr} />
                                            )}
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}><strong>{label}</strong></Grid>
                                                <Grid item xs={12}>
                                                    <FormControl fullWidth>
                                                        <InputLabel htmlFor={`s${v}_nome`}>Nome Completo *</InputLabel>
                                                        <Input 
                                                        id={`s${v}_nome`}
                                                        onChange={(e) => {
                                                            let currentData = data
                                                            currentData[`s${v}_nome`] = e.target.value
                                                            setData(currentData)
                                                        }}
                                                        value={data[`s${v}_nome`]}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControl fullWidth>
                                                        <InputLabel htmlFor={`s${v}_email`}>Email *</InputLabel>
                                                        <Input 
                                                        id={`s${v}_email`}
                                                        onChange={(e) => {
                                                            let currentData = data
                                                            currentData[`s${v}_email`] = e.target.value
                                                            setData(currentData)
                                                        }}
                                                        value={data[`s${v}_email`]}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControl fullWidth>
                                                        <InputLabel htmlFor={`s${v}_cpf`}>CPF *</InputLabel>
                                                        <Input 
                                                        id={`s${v}_cpf`}
                                                        onChange={(e) => {
                                                            let currentData = data
                                                            currentData[`s${v}_cpf`] = e.target.value
                                                            setData(currentData)
                                                        }}
                                                        inputComponent={CpfMask}
                                                        value={data[`s${v}_cpf`]}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControl fullWidth>
                                                        <InputLabel htmlFor={`s${v}_telefone`}>Telefone *</InputLabel>
                                                        <Input 
                                                        id={`s${v}_telefone`}
                                                        onChange={(e) => {
                                                            let currentData = data
                                                            currentData[`s${v}_telefone`] = e.target.value
                                                            setData(currentData)
                                                        }}
                                                        inputComponent={TelefoneMask}
                                                        value={data[`s${v}_telefone`]}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControl fullWidth>
                                                        <InputLabel htmlFor={`s${v}_celular`}>Celular *</InputLabel>
                                                        <Input 
                                                        id={`s${v}_celular`}
                                                        onChange={(e) => {
                                                            let currentData = data
                                                            currentData[`s${v}_celular`] = e.target.value
                                                            setData(currentData)
                                                        }}
                                                        inputComponent={CelularMask}
                                                        value={data[`s${v}_celular`]}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Fragment>
                                    )
                                })}
                                
                                <div className={classes.buttonContainer}>
                                    <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                                        Voltar
                                    </Button>

                                    <Button
                                        variant='contained'
                                        color='primary'
                                        onClick={validarSocios}
                                        className={classes.button}
                                    >
                                        Próximo
                                    </Button>
                                </div>
                            </Fragment>
                        )}

                        {/* STEP 3 */}
                        {activeStep === 2 && (
                            <Fragment>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} className={classes.termsAndConditions}>
                                        <div className={classes.termsContainer} onScroll={handleScroll}>
                                            {/* <Contrato /> */}
                                            <ContratoV3 />
                                            <div className={classes.buttonContainer}>
                                                <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                                                    Voltar
                                                </Button>

                                                <Button
                                                    variant='contained'
                                                    color='primary'
                                                    onClick={handleNext}
                                                    className={classes.button}
                                                    // disabled={!termsRead}
                                                >
                                                    Concordo com os Termos e Condições
                                                </Button>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Fragment>
                        )}

                        {/* STEP 4 */}
                        {activeStep === 3 && (
                            <Fragment>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} className={classes.termsAndConditions}>
                                        <FormControlLabel
                                            control={<Checkbox checked={accept1} onChange={() => setAccept1(!accept1)} color="primary" />}
                                            label="Concordo que li e aceito todos os termos e condições."
                                        />
                                    </Grid>
                                    <Grid item xs={12} className={classes.termsAndConditions}>
                                        <FormControlLabel
                                            control={<Checkbox checked={accept2} onChange={() => setAccept2(!accept2)} color="primary" />}
                                            label="Concordo que os dados declarados são todos verdadeiros e que sou responsável por qualquer tipo de irregularidade que possa ser encontrada neles."
                                        />
                                    </Grid>
                                </Grid>
                                
                                <div className={classes.buttonContainer}>
                                    <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                                        Voltar
                                    </Button>

                                    <Button
                                        variant='contained'
                                        color='primary'
                                        onClick={submit}
                                        className={classes.button}
                                        disabled={!(accept1 && accept2)}
                                    >
                                        Finalizar
                                    </Button>
                                </div>
                            </Fragment>
                        )}

                        {/* STEP 5 */}
                        {activeStep === 4 && (
                            <Fragment>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        {enviando === 0 && (
                                            <p><strong>Enviando dados...</strong></p>
                                        )}
                                        {enviando === 1 && (
                                            <Fragment>
                                                <p><strong>Dados enviados com sucesso!</strong></p>
                                                <p>O email para confirmação foi enviada para o representante legal.</p>
                                                <p>Para concluir clique no link do email para finalizar a contratação!</p>
                                                <p>Obrigado!</p>
                                            </Fragment>
                                        )}
                                    </Grid>
                                </Grid>
                            </Fragment>
                        )}
                    </div>
                </Paper>
            </Container>
        </Fragment>
    )
}

export default Index